body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content {
  position: relative;
  top: 45px;
  width: 90%;
  margin: 0 auto;
}

.hidden {
    display: none;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:50px;
    justify-content: center;
}
.lds-roller {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-roller div {
    -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    -webkit-transform-origin: 32px 32px;
            transform-origin: 32px 32px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #000;
    margin: -3px 0 0 -3px;
  }
  .lds-roller div:nth-child(1) {
    -webkit-animation-delay: -0.036s;
            animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }
  .lds-roller div:nth-child(2) {
    -webkit-animation-delay: -0.072s;
            animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  .lds-roller div:nth-child(3) {
    -webkit-animation-delay: -0.108s;
            animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  .lds-roller div:nth-child(4) {
    -webkit-animation-delay: -0.144s;
            animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  .lds-roller div:nth-child(5) {
    -webkit-animation-delay: -0.18s;
            animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  .lds-roller div:nth-child(6) {
    -webkit-animation-delay: -0.216s;
            animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  .lds-roller div:nth-child(7) {
    -webkit-animation-delay: -0.252s;
            animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  .lds-roller div:nth-child(8) {
    -webkit-animation-delay: -0.288s;
            animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
  @-webkit-keyframes lds-roller {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes lds-roller {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
.student-lookup__container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 30px;
    padding-bottom: 80px;
}

.student-lookup__search-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.student-lookup__search-button {
    margin-bottom: 8px !important;
}

.student-lookup__class {
    margin: 0 5px;
}

.student-lookup__search-results {
    display: flex;
    flex-direction: row;
    width: 100%;
}

@media (min-width: 480px) {
    .student-lookup__search-results {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.student-lookup__student-info {
    flex-direction: column;
    width: 100%;
    padding: 20px;
    margin: 24px 15px 0 5px;
}

@media (min-width: 480px) {
    .student-lookup__student-info {
        flex-direction: column;
        width: 400px;
        padding: 20px;
        margin: 24px 15px 0 5px;
    }
}

.student-lookup__loading {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.student-lookup__hide {
    display: none;
}

.student-lookup__show {
    display: flex;
}

.student-lookup__no-results {
    margin-top: 20px;
    text-align: center;
}

.student__link {
    text-decoration: none;
}

.student__card h3, h4, h5{
    color: rgba(0,0,0,0.87);
}
.student__card {
    padding: 20px;
    margin-bottom: 20px;
}

.student__terms-container {
    padding: 10px;
    color: rgba(0,0,0,0.87);
}

.hide {
    display: none;
}

.show {
    display: block;
}

.student__detail-container {
    display: flex;
    flex-direction: column;
}
.student__detail-heading {
margin-bottom: 5px;
}

.student__detail-text {
margin-top: 10px;
}

.student__info-container {
    display: flex;
    flex-direction: row;
    padding: 25px;
    flex-wrap: wrap;
}

.student__info-section {
    width: 400px;
}

.student__info-section-large {
    width: 100%;
}

.student__info-subtitle {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
}

.student__show {
    display: block;
}

.student__hide {
    display: none;
}

.student__loading {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.student__cta-container {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
}

.student__delete-icon {
    margin-right: 5px;
}

.student__back-icon {
    font-size: 40px !important;
    cursor: pointer;
    position: relative;
    top: 10px;
    margin-right: 15px;
}

.student__status-label {
    font-weight: bold;
}

.student__status-label.registered {
    color: green;
}

.student__status-label.pending {
    color: red;
}


.class-editor__link {
    text-decoration: none;
}

.class-editor__card h3, h4, h5{
    color: rgba(0,0,0,0.87);
}
.class-editor__card {
    padding: 20px;
    margin-bottom: 20px;
}

.class-editor__terms-container {
    padding: 10px;
    color: rgba(0,0,0,0.87);
}

.class-editor__active {
    color: green;
}

.class-editor__inactive {
    color: red;
}
.navbar-bar {
    width: 100%;
    min-height: 30px;
    background-color: #eee;
    padding: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    z-index: 20;
}

@media (min-width: 768px) {
    .navbar-bar {
        flex-direction: row;
    }
}

.navbar-anchor {
    display: flex;
    flex-direction: row;
}

.navbar-logo img {
    max-height: 30px;
}

@media (min-width: 768px) {
    .navbar-logo img {
        padding-left: 10px;
    }
}

.navbar-hamburger {
    display: inline;
    margin: 5px 15px 0 5px;
}

@media (min-width: 768px) {
    .navbar-hamburger {
        display: none;
    }
}

.navbar-menu {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 5px;
    -webkit-transition: max-height 1s;
    transition: max-height 1s;
}

@media (min-width: 768px) {
    .navbar-menu {
        flex-direction: row;
    }
}

.navbar-menu.hidden {
    display: none;
}

@media (min-width: 768px) {
    .navbar-menu.hidden {
        display: flex;
    }
}

.navbar-menu-link {

    margin: 10px 0px 10px 0;
}

@media (min-width: 768px) {
    .navbar-menu-link {
        display: flex;
        margin: 0 20px 0 20px;
    }
}

.navbar-menu-link a {
    color: #bc9b6a;
    text-decoration: none;
}

.navbar-menu-link a:hover {
    color: #9e7b47;
}

