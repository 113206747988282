.hidden {
    display: none;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:50px;
    justify-content: center;
}