.student__link {
    text-decoration: none;
}

.student__card h3, h4, h5{
    color: rgba(0,0,0,0.87);
}
.student__card {
    padding: 20px;
    margin-bottom: 20px;
}

.student__terms-container {
    padding: 10px;
    color: rgba(0,0,0,0.87);
}

.hide {
    display: none;
}

.show {
    display: block;
}

.student__detail-container {
    display: flex;
    flex-direction: column;
}
.student__detail-heading {
margin-bottom: 5px;
}

.student__detail-text {
margin-top: 10px;
}

.student__info-container {
    display: flex;
    flex-direction: row;
    padding: 25px;
    flex-wrap: wrap;
}

.student__info-section {
    width: 400px;
}

.student__info-section-large {
    width: 100%;
}

.student__info-subtitle {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
}

.student__show {
    display: block;
}

.student__hide {
    display: none;
}

.student__loading {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.student__cta-container {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
}

.student__delete-icon {
    margin-right: 5px;
}

.student__back-icon {
    font-size: 40px !important;
    cursor: pointer;
    position: relative;
    top: 10px;
    margin-right: 15px;
}

.student__status-label {
    font-weight: bold;
}

.student__status-label.registered {
    color: green;
}

.student__status-label.pending {
    color: red;
}
