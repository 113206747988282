.student-lookup__container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 30px;
    padding-bottom: 80px;
}

.student-lookup__search-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.student-lookup__search-button {
    margin-bottom: 8px !important;
}

.student-lookup__class {
    margin: 0 5px;
}

.student-lookup__search-results {
    display: flex;
    flex-direction: row;
    width: 100%;
}

@media (min-width: 480px) {
    .student-lookup__search-results {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.student-lookup__student-info {
    flex-direction: column;
    width: 100%;
    padding: 20px;
    margin: 24px 15px 0 5px;
}

@media (min-width: 480px) {
    .student-lookup__student-info {
        flex-direction: column;
        width: 400px;
        padding: 20px;
        margin: 24px 15px 0 5px;
    }
}

.student-lookup__loading {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.student-lookup__hide {
    display: none;
}

.student-lookup__show {
    display: flex;
}

.student-lookup__no-results {
    margin-top: 20px;
    text-align: center;
}
